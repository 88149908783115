@use '@angular/material' as mat;
@import "angular-ui-styles/assets/assets/styles/_colors.scss"; 
@import "angular-ui-styles/assets/assets/styles/_typography.scss";
@import 'angular-ui-styles/assets/assets/styles/_theme.scss';


$theme-color: mat.get-color-from-palette($custom-theme-primary-2, '$primary-2');

/// overwrite buttons style
@mixin mat-button-component-theme($theme-color) {
    .mdc-button {
        display: flex !important;
        &.nsc-btn__right{
            direction: rtl;
            .mat-icon{
                padding-left: 5px;
            }
        }
        // &.nsc-btn--small{
         .mat-icon{
            padding-top: 2px;
           
         }
      
    }
}

// overwrite radio buttons
@mixin mat-radio-component-theme($theme-color) {
    .education-solutions-theme .mat-mdc-radio-group .mat-mdc-radio-button:not(:last-child){
        margin-right: 5px;
    }
    .mat-mdc-radio {
        width: 0;
        opacity: 0;
       
        &:disabled{
            background-color: aqua;
        }
        &-button {
            display: inline-flex;
            -webkit-transition: background-color 0.2s ease;
            -o-transition: background-color 0.2s ease;
            transition: background-color 0.2s ease;
            min-width: 57px;
            height: 34px;
            border-radius: 4px;
            text-transform: uppercase;
            border: 1px solid var(--brand-color-1);
            // &:not(:last-child){
               
            // }
            &.cdk-focused{
                outline: 2px solid #292929;
                &:not(.mat-mdc-radio-checked){
                    background-color: #e8efe5;
                }
            }

            &:hover {
                cursor: pointer;
                background-color: var(--brand-color-7);
                border-color: var(--brand-color-2);
                .mdc-label{
                    color: var(--brand-color-2);
                }
            }

           
            .mdc-label{
                margin-left: -7px;
                color:var(--brand-color-1);
                font-weight: 600;
              }

              .mdc-radio{
                width: 0;
                opacity: 0;
              }
        }
        // when btn is checked
        &-checked{
            color: lighten(#e8e8e8, 100%);
            background-color: var(--brand-color-1);
            border-color: var(--brand-color-1);
            .mdc-label{
                color: #fff;
            }
            &:hover {
                cursor: pointer;
                background-color: var(--brand-color-2);
                border-color: var(--brand-color-2);
                .mdc-label{
                    color: #fff;
                }
            }
        }
    }

 
}