/*!
 * Bootstrap Grid v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@at-root {
  @-ms-viewport {
    width: device-width;
  }
}

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
$grid-gutter-width: 40px;

@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/mixins/grid-framework';
@import 'bootstrap/scss/mixins/grid';

@import 'bootstrap/scss/grid';
@import 'bootstrap/scss/utilities/display';
@import 'bootstrap/scss/utilities/flex';
