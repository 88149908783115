@import 'styles/variables';

// layout of DIVs with borders that make it look like a table;
// not using an actual TABLE because it is not tablular data, just visual;
.nsc-txt{
font-weight: 500;
}
.nsc-table {
  border-top:1px solid var(--brand-color-4);
 

  // TR;
  &__row {
    border-bottom: 1px solid var(--brand-color-4);
    display: flex;

    // zebra stripes;
    &:nth-child(odd) {
      background-color: var(--brand-color-7);
    }
    &--total{
      background-color: transparent !important;
      
    }
  }

  // TD;
  &__col {
    padding: 10px 20px;

    // undo the default padding for form fields (it's too much spacing);
    &--form-field {
      padding: 10px 20px 0; // assuming no hint, if a hint is used, you'll need another BEM modifier;
    }

    // text content;
    &--label {
      width: calc(100% - 110px);
     &--total{
        display: inline-flex;
      align-items: center;
      justify-content: flex-end;
     }
    }

    // number content;
    // vetically and right align content (content is numbers/currency);
    &--value {
      width: 110px;
      border-left: 1px solid var(--brand-color-4);
      display: flex;
      align-items: center;
      justify-content: flex-end;
      
    }

    // last row total, highlight this value;
    &--total {
      strong{
        color: var(--brand-color-1);
      }
    }
  }
  // .education-solutions-theme .mat-icon-button{
  //  color: $layout-color-1; 
  // }
}
