@use '@angular/material' as mat;
@import 'breakpoint-sass/stylesheets/breakpoint';
@import "angular-ui-styles/assets/assets/styles/_colors.scss"; 

@import 'styles/variables';
@import 'global/nsc-form-actions';
@import 'global/nsc-table';
@import 'global/nsc-row';

@import 'custom';


body {
  background: #f4f4f4e0;
}

.nsc-hidden{
 display: none;
}

// container for everything within the BODY;
.nsc-app-root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  &:focus-visible{
    outline-color: transparent;
  }
}

// container for the main content of the page;
.nsc-content {
  flex-grow: 1;
}

// custom horizontal rule, apply to an HR tag;
.nsc-hr {
  margin: 0px -15px;
  border: 0;
  border-top: 1px solid var(--layout-color-3);

}
 




.nsc-order-total {
  border: 2px solid var(--brand-color-1);
  background: #fff;
  padding: 20px;
  font-size: 16px;

  strong {
    color: var(--brand-color-1);
  }
}



// Overriding styles for input underlines during loading
.nsc-form-field-loading {
  .mat-form-field-underline {
    height: 2px !important;
    background: linear-gradient(
      to right,
      $color-gray-dark,
      $color-gray-dark 25%,
      #fff 50%,
      $color-gray-dark 75%,
      $color-gray-dark 100%
    ) !important;
    animation: gradientAnimation 2s ease infinite !important;
    background-size: 300% 100% !important;
  }
  .mat-form-field-ripple {
    display: none !important;
  }
}
 
.nsc-listitem {
  padding: 0px;

  li {
    display: inline-block;
    padding-right: 0.8rem
  }
}
  

@keyframes gradientAnimation {
  0% {
    background-position: 100% 50%;
  }
  50% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
 
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/MaterialIcons-Regular.eot'); /* For IE6-8 */
  src: url('../assets/MaterialIcons-Regular.woff2') format('woff2'),
    url('../assets/MaterialIcons-Regular.woff') format('woff'),
    url('../assets/MaterialIcons-Regular.ttf') format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}

/** IE 11 fixes */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .mat-select-panel {
    min-width: inherit !important;
  }
  /** IE 11 fixes 
      Temporary fix for this issue: https://github.com/angular/material2/pull/11801
  */
  .cdk-overlay-pane {
    display: block;
  }
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}



.nsc-icon__svg{
  width: 20px !important;
  height: 20px !important;
}