// override Bootstrap grid spacing;
// used when we want a grid, but not the default (40px gutters);

.nsc-row--20 {
  margin: 0 -10px;

  .col,
  .col-auto,
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12 {
    padding: 0 10px;
  }
}

.nsc-row--12 {
  margin: 0 -6px;

  .col,
  .col-auto,
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12 {
    padding: 0 6px;
  }
}
